/* body {
  color: #588600;
  font-weight: bold;
    padding-top: 65px;
}
.main {
  min-height: 50vh;
  background-color: white;
}
.main-nav {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 200;
}
.navbar-brand {
  padding: 0px;
}
.brand-logo {
  max-height: 45px;
}
.container-fluid {
  padding: 0px;
}
.navbar {
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: #DADD00;
  min-height: 65px;
}
a.nav-link {
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: 900;
color: black
}
a.nav-link:hover, a.nav-link.active {
  background-color: #A4A629;
}
.sub-menu {
  text-align: center;
  background-color: #A4A629;
  line-height: 2.5em;
  padding: 0px 50px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.image-menu {
  display: block;
  padding: 10px;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
a.image-menu {
  color: black;
}
.image-menu:hover {
  background-color: #DADD00;
  /*transform: scale(1.15);*/
/*border-radius: 2px;*/
/*transition: all 0.6s;*/
/*
}
.slider .carousel-item {
  background-color: #1A912A;
  height: 350px;
  overflow: hidden;
  text-align: center;
}
.slider .carousel-caption {
  color: black;
}
.slider .carousel-caption h3 {
font-weight: 500;
font-size: 40px;
}
.slider .carousel-caption p {
font-weight: 900;
font-size: 30px;
}
.slider img {
  min-height: 100%;
  width: 100%;
}
.items {
  padding-top: 20px;
}
.items h3 {
  display: block;
  padding: 10px;
  padding-bottom: 0px;
  border-bottom: 3px solid #DADD00;
  font-size: 1.8rem;
  color: black;
}
.items .row > div {
  margin-top: 15px;
}
.items .item {
  border: 1px solid #DADD00;
  text-align: center;
} */
.hide {
  display: none !important;
}

.top_header_area {
  height: auto;
  padding-top: 30px;
}

.top_logo {
  text-align: center;
  width: 100%;
}

.logo {
  height: 70px;
}

.main_header_area {
  height: auto;
  padding-top: 5px;
  padding-bottom: 15px;
}

.welcome_slides .welcome_slide_text {
  margin-top: 55vh;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 15px 25px;
}

.welcome_slide_text h2 {
  color: white;
}

.welcome_slides .bg-img {
  background-position-y: bottom;
  height: 80vh;
}

@media screen and (max-width: 1024px) {
  .welcome_slides .bg-img {
    max-height: 50vh;
  }

  .welcome_slides .welcome_slide_text {
    margin-top: 55%;
    padding: 10px 15px;
  }

  .welcome_slide_text h2 {
    font-size: 1.3rem !important;
  }
}

.nav-item:hover>a {
  opacity: 0.7;
  -webkit-animation: text-pop-up-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: text-pop-up-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.nav-item.dropdown:hover .dropdown-menu {
  display: block;
  border-radius: 0px;
  border: 0px;
  box-shadow: 1px 1px rgba(0, 0, 0, .2);
  margin-top: 0px;
}

.nav-item.dropdown:hover .dropdown-menu a:hover {
  background-color: rgba(188, 190, 58, 0.3);
  color: black;
  opacity: 0.8;
  -webkit-animation: text-pop-up-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: text-pop-up-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes text-pop-up-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform-origin: 2% 2%;
    transform-origin: 2% 2%;
    text-shadow: none;
  }

  100% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
    -webkit-transform-origin: 2% 2%;
    transform-origin: 2% 2%;
    text-shadow: 0 1px 0 #cccccc, 0 1px 0 #cccccc, 0 1px 0 #cccccc, 0 1px 0 #cccccc, 0 1px 0 #cccccc, 0 1px 0 #cccccc, 0 1px 0 #cccccc, 0 1px 0 #cccccc, 0 1px 0 #cccccc, 0 1px 1px rgba(0, 0, 0, 0.3);
  }
}

@keyframes text-pop-up-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform-origin: 2% 2%;
    transform-origin: 2% 2%;
    text-shadow: none;
  }

  100% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
    -webkit-transform-origin: 2% 2%;
    transform-origin: 2% 2%;
    text-shadow: 0 3px 0 #cccccc, 0 3px 0 #cccccc, 0 3px 0 #cccccc, 0 3px 0 #cccccc, 0 3px 0 #cccccc, 0 3px 0 #cccccc, 0 3px 0 #cccccc, 0 3px 0 #cccccc, 0 3px 0 #cccccc, 0 5px 5px rgba(0, 0, 0, 0.3);
  }
}

.footer-logo {
  text-align: center;
}

.footer-logo img {
  max-height: 150px;
}

.copyright-footer {
  text-align: center;
  font-size: 12px;
}

.category-title {
  text-align: center;
  margin-bottom: 75px;
  margin-top: 50px;
}

.shop_grid_area {
  margin-top: 25px;
}

.shop_grid_area .section_heading {
  margin-bottom: 0px;
}

.shop_grid_area .section_heading h2 {
  font-size: 38px;
}

.shop_grid_area .item-list {
  margin-top: 50px;
}

.menu-list {
  margin-top: 0px;
}

.widget {
  padding-bottom: 25px;
  margin-top: 25px;
  border-bottom: 0px;
}

.single_gallery_item {
  cursor: pointer;
}

.infinite-scroll-component {
  overflow: hidden !important;
}

button.image-gallery-thumbnail.active,
button.image-gallery-thumbnail:hover {
  border-color: rgba(188, 190, 58, 0.3);
}

button.image-gallery-icon:hover {
  color: rgba(188, 190, 58, 0.3)
}

button.image-gallery-icon {
  filter: drop-shadow(0 1px 1px #ccc)
}

.discount-product {
  width: 100%;
  position: absolute;
  top: 0px;
  text-align: center;
  padding: 10px 0;
  background-color: rgb(255, 0, 0, 0.9);
  color: white;
}

.discount .product-price span+span,
.single_product_details_area .single_product_desc .price span+span {
  font-size: 15px;
  padding-left: 10px;
  text-decoration: line-through;
  color: red;
}

.single_product_desc .badge {
  color: white;
  margin-bottom: 15px;
}

.owl-carousel {
  margin-bottom: 50px;
}

button.image-gallery-icon.image-gallery-fullscreen-button {
  width: 100%;
  height: 100%;
  color: transparent;
  cursor: pointer;
  z-index: 3;
}